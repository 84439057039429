<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <v-container class="pa-0">
            <v-row no-gutters>
                <template v-if="model.type==='mobile'">
                    <v-col cols="6">
                        <v-select
                            slot="prepend"
                            v-model="model.country"
                            :items="countries"
                            item-text="text"
                            item-value="value"
                            :label="$t('views.passport.forgotPassword.country')"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="model.mobilePhone"
                            :rules="[rules.required]"
                            :validate-on-blur="false"
                            :error="hasError('mobilePhone')"
                            :error-messages="getError('mobilePhone')"
                            :label="$t('views.passport.forgotPassword.mobilePhone')"
                            :counter="phoneNumberLength"
                            name="mobilePhone"
                            @change="resetError('mobilePhone')"
                        />
                    </v-col>
                </template>
                <template v-else-if="model.type==='email'">
                    <v-col cols="12">
                        <v-text-field
                            v-model="model.email"
                            :rules="[rules.required, rules.email]"
                            :validate-on-blur="false"
                            :error="hasError('email')"
                            :error-messages="getError('email')"
                            :label="$t('views.passport.forgotPassword.email')"
                            name="email"
                            @change="resetError('email')"
                        />
                    </v-col>
                </template>

                <v-col cols="12">
                    <v-text-field
                        v-model="model.captcha"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        :error="hasError('captcha')"
                        :error-messages="getError('captcha')"
                        :label="$t('views.passport.forgotPassword.captcha')"
                        :counter="passportViewModel.captchaOptions.length"
                        :disabled="captchaInputDisabled"
                        name="captcha"
                        autocomplete="off"
                        @change="resetError('captcha')"
                    >
                        <v-btn slot="append" color="primary" text :disabled="sendCaptchaButtonDisabled" @click="sendCaptcha">
                            {{ countDownSeconds > 0 ? `(${countDownSeconds}s)` : $t('views.passport.forgotPassword.sendCaptcha') }}
                        </v-btn>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-btn class="mt-2" type="submit" color="primary" block x-large :loading="isSubmitting" :disabled="submitButtonDisabled">
            {{ $t('views.passport.forgotPassword.button') }}
        </v-btn>
    </v-form>
</template>

<script>
import _ from 'lodash';
import utils from '@/utils';
import FormMixins from '@/mixins/FormMixins';
import { sendResetPasswordSmsCaptcha, sendResetPasswordEmailCaptcha, resetPasswordPreCheck } from '@/api/passport';
import countries from '@/models/countries';

export default {
    mixins: [FormMixins],
    props: {
        passportViewModel: Object
    },
    data () {
        return {
            isSubmitting: false,

            // 表单
            isFormValid: false,

            model: {
                type: '',
                country: 'none',
                mobilePhone: '',
                email: '',
                captcha: '',
                captchaId: null
            },

            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required'),
                email: (value) => (value && utils.validate.isEmail(value)) || this.$t('validation.invalidEmailFormat')
            },
            // 验证码倒计时
            countDownSeconds: 0
        };
    },
    computed: {
        countries () {
            return _(countries)
                .filter((c) => {
                    return this.passportViewModel.smsOptions.supportCountries.indexOf(c.value) >= 0;
                })
                .map((c) => {
                    return {
                        value: c.value,
                        text: this.$t(`models.countries.${c.value}`) + ` (+${c.callingCode})`,
                        phoneNumberLength: c.phoneNumberLength
                    };
                })
                .value();
        },
        phoneNumberLength () {
            return _.find(countries, (c) => c.value === this.model.country).phoneNumberLength;
        },
        captchaInputDisabled () {
            if (this.model.type === 'mobile') {
                return !(this.model.mobilePhone.length === this.phoneNumberLength);
            } else if (this.model.type === 'email') {
                return !(this.model.email && utils.validate.isEmail(this.model.email));
            } else {
                return true;
            }
        },
        sendCaptchaButtonDisabled () {
            return this.isSubmitting || this.captchaInputDisabled || this.countDownSeconds > 0;
        },
        submitButtonDisabled () {
            var isValidModel =
                this.model.type === 'mobile'
                    ? (this.model.mobilePhone && this.model.mobilePhone.length === this.phoneNumberLength)
                    : this.model.type === 'email'
                        ? this.model.email && utils.validate.isEmail(this.model.email)
                        : false;
            var isValidCaptcha =
                this.model.captcha &&
                this.model.captcha.length === this.passportViewModel.captchaOptions.length &&
                this.model.captchaId;
            return this.isSubmitting || !(isValidModel && isValidCaptcha);
        }
    },
    methods: {
        async sendCaptcha () {
            try {
                if (this.model.type === 'mobile') {
                    this.model.captchaId = await sendResetPasswordSmsCaptcha({
                        country: this.model.country,
                        mobilePhone: this.model.mobilePhone
                    });
                } else if (this.model.type === 'email') {
                    this.model.captchaId = await sendResetPasswordEmailCaptcha({
                        email: this.model.email
                    });
                } else {
                    throw new Error('Unknown type');
                }

                this.model.captcha = '';

                this.countDownSeconds = 60;
                var countDown = () => {
                    if (--this.countDownSeconds <= 0) {
                        clearInterval(countDownId);
                    }
                };
                var countDownId = setInterval(countDown, 1000);
            } catch (ex) {
                this.$toast.error(ex.message);
            }
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            try {
                var secret = await resetPasswordPreCheck(this.model);
                this.$emit('submitted', { secret });
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        }
    },
    mounted () {
        // 当注册类型是手机，那么就用手机找回，否则用邮箱
        this.model.type = this.passportViewModel.passportOptions.registerType;
        if (this.model.type === 'mobile') {
            this.model.country = this.passportViewModel.smsOptions.defaultCountry;
        }
    }
};
</script>
