<template>
    <v-card class="text-center pa-1" :loading="isLoading">
        <v-card-title class="justify-center display-1 mb-2">
            {{ step === 'verify' ? $t('views.passport.forgotPassword.title') : $t('views.passport.resetPassword.title') }}
        </v-card-title>
        <v-card-text v-if="passportViewModel">
            <v-window v-model="step" :touchless="true">
                <v-window-item value="verify">
                    <Verify
                        :passportViewModel="passportViewModel"
                        @submitted="_$onVerified"
                    />
                </v-window-item>
                <v-window-item value="reset">
                    <Reset
                        :secret="secret"
                        :passportViewModel="passportViewModel"
                    />
                </v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script>
import { getPassportViewModel } from '@/api/passport';
import Verify from './Verify';
import Reset from './Reset';

export default {
    components: {
        Verify,
        Reset
    },
    data () {
        return {
            isLoading: false,
            step: 'verify',
            secret: null,
            passportViewModel: null
        };
    },
    methods: {
        _$onVerified ({ secret }) {
            this.secret = secret;
            this.step = 'reset';
        }
    },
    async created () {
        this.isLoading = true;
        this.passportViewModel = await getPassportViewModel({});
        this.isLoading = false;
    }
};
</script>
