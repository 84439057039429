<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit" class="my-2">
        <v-text-field
            v-model="model.newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showNewPassword ? 'text' : 'password'"
            :error="hasError('newPassword')"
            :error-messages="getError('newPassword')"
            :label="$t('views.passport.resetPassword.newPassword')"
            name="newPassword"
            outlined
            @change="resetError('newPassword')"
            @click:append="showNewPassword = !showNewPassword"
        />
        <v-text-field
            v-model="model.confirmPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showNewPassword ? 'text' : 'password'"
            :error="hasError('confirmPassword')"
            :error-messages="getError('confirmPassword')"
            :label="$t('views.passport.resetPassword.confirmPassword')"
            name="confirmPassword"
            outlined
            @change="resetError('confirmPassword')"
            @click:append="showNewPassword = !showNewPassword"
        />
        <v-btn type="submit" color="primary" x-large block :loading="isSubmitting" :disabled="isSubmitting">{{ $t('common.save') }}</v-btn>
    </v-form>
</template>

<script>
import _ from 'lodash';
import FormMixins from '@/mixins/FormMixins';
import { resetPassword } from '@/api/passport';

export default {
    mixins: [FormMixins],
    props: {
        passportViewModel: Object,

        secret: String
    },
    data () {
        return {
            isSubmitting: false,
            isFormValid: false,
            showNewPassword: false,

            model: {
                newPassword: '',
                confirmPassword: ''
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            }
        };
    },
    methods: {
        async submit (e) {
            e.preventDefault();
            var form = this.$refs.form;
            if (!form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            try {
                var postData = _.merge({}, this.model, { secret: this.secret });
                await resetPassword(postData);

                this.$toast.info(this.$t('views.passport.resetPassword.success'));
                form.reset();

                // 跳转回登录界面
                this.$router.push({ path: '/' }).catch(err => err);
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        }
    }
};
</script>
